
export class PilotProgramPage{

    constructor() {
        $(() => {
            // show first year table, hide the others
            let awardeeTables = $(".awardee-table").map(function () {
                return $(this);
            })

            for (let i = 0; i < awardeeTables.length; i++) {
                if (i != 0) {
                    awardeeTables[i].addClass("inactive-section");
                }
            }

            $(".year-filter").click(function () {
                // Make sure the clicked tab is not active
                if (!$(this).parent().hasClass("active")) {
                    // hide all awardee tables
                    $(".awardee-table").addClass("inactive-section");

                    // show the correct one
                    let awardeeTableId = "#" + $(this).val();
                    $(awardeeTableId).removeClass("inactive-section");
                }
            })
        });
    }
}