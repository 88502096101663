import { ResearchCenter, Site } from "./entities";
import { ShuffleArray } from '../../../shared/scripts/array-functions';
import { NetworkMap } from "./network-map.map";
import { Unique } from "../../../shared/scripts/array-functions";

export class NetworkMapPage {

    map: NetworkMap;
    researchCenters: ResearchCenter[];
    visibleRCs: ResearchCenter[];

    constructor() {
        $(() => {

            this.loadResearchCenters();

            ShuffleArray(this.researchCenters);

            //console.log(this.researchCenters);
            this.visibleRCs = this.researchCenters;

            this.initializeLegend();

            let pathogenList: string[] = [];
            this.researchCenters.forEach((rc: ResearchCenter) => {
                rc.sites.forEach((s: Site) => {
                    s.pathogenFamilyList.forEach((pathFam: string) => {
                        if (!pathogenList.includes(pathFam)) {
                            pathogenList.push(pathFam);
                        }
                    });
                });
            });
            this.initializeDropdown('#pathogens-dropdown', Unique(pathogenList, true, ["Not Available"]));

            this.map = new NetworkMap(this.researchCenters, $('#pathogens-dropdown').val());
        });
    }

    initializeLegend(): void {
        for (let i: number = 0; i < this.researchCenters.length; ++i) {
            $('#rc' + i).find('#label').html(this.researchCenters[i].name);
            $('#rc' + i).find('#svg').attr('stroke', this.researchCenters[i].color);
            $('#rc' + i).find('#svg').attr('fill', this.researchCenters[i].color);
        }
    }

    //could be moved to utility - like in RECOVER-CT codebase
    initializeDropdown(selectId: string, values: string[]): void {
        $(selectId).selectpicker({
            dropupAuto: false,
            selectedTextFormat: "count",

            countSelectedText: (numSelected, numTotal) => {
                if (numSelected == numTotal) {
                    return "All Pathogen Families";
                }
                else {
                    return numSelected + " Selected";
                }
            }
        });

        values.forEach((element: any) => {
            $(selectId).append(new Option(element, element, false, true));
        });
        $(selectId).selectpicker("refresh");

        $(selectId).on('changed.bs.select', (e, clickedIndex, isSelected, previousValue) => {
            this.map.setMarkers(this.visibleRCs, $('#pathogens-dropdown').val());
        });
    }

    updateVisibleResearchCenters(): void {
        this.visibleRCs = [];

        for (let i: number = 0; i < this.researchCenters.length; ++i) {
            if ($('#check-' + i).is(':checked')) {
                this.visibleRCs.push(this.researchCenters[i]);
            }
        }
        this.map.setMarkers(this.visibleRCs, $('#pathogens-dropdown').val());
    }

    selectAllResearchCenters(): void {
        for (let i: number = 0; i < this.researchCenters.length; ++i) {
            $('#check-' + i).prop('checked', true);
        }
        this.map.setMarkers(this.researchCenters, $('#pathogens-dropdown').val());
    }

    deselectAllResearchCenters(): void {
        for (let i: number = 0; i < this.researchCenters.length; ++i) {
            $('#check-' + i).prop('checked', false);
        }
        this.map.setMarkers([], []);
    }

    loadResearchCenters() {

        this.researchCenters = [];

        let cc: ResearchCenter = new ResearchCenter("CREID CC", "CREID Coordinating Center", [35.55, -78.52], "#7b5336", [], [], "creid-cc", true);
        cc.addSite("Duke University", [36.0014, -78.9382], "Coronaviruses | Flaviviruses | Hantaviruses | Phenuiviruses | Togaviruses | Paramyxoviruses | Picornaviruses");
        cc.addSite("Biolab Diagnostic Laboratories", [31.963158, 35.930359], "Coronaviruses");
        cc.addSite("Washington State University, College of Veterinary Medicine", [47.6062095, -122.3320708], "Coronaviruses");
        cc.addSite("Universidad Federal de Minas Gerais, Institute of Biological Sciences", [-19.9166813, -43.9344931], "Flaviviruses | Togaviruses");
        cc.addSite("University of Nairobi, KAVI Institute of Clinical Research", [-1.286389, 36.817223], "Coronaviruses");
        cc.addSite("Universidade Federal de Mato Grasso, Faculdade de Medicina Veterinaria", [-15.601411, -56.097892], "Arboviruses");
        cc.addSite("Oxford University Clinical Research Unit", [10.762622, 106.660172], "Coronaviruses | Paramyxoviruses");
        cc.addSite("Nature Conservation Society Myanmar", [16.871311, 96.199379], "Coronaviruses");
        cc.addSite("Center for Molecular Dynamics Nepal", [27.700769, 85.30014], "Coronaviruses");
        //cc.addSite("Duke-NUS Medical School", [1.352083, 103.819836], "Coronaviruses");
        cc.addSite("Institut Pasteur du Cambodge", [11.5563738, 104.9282099], "Bunyaviruses");
        cc.addSite("Washington State University", [47.6062095, -122.3320708], "Coronaviruses | Phenuviruses");
        cc.addSite("University of Texas Medical Branch (UTMB)", [29.3013479, -94.7976958], "Arboviruses | Arenaviruses | Hantaviruses");
        cc.addSite("Institut Pasteur de Dakar", [14.716677, -17.4676861], "Arboviruses");
        cc.addSite("Instituto Nacional de Enfermedades Virales Humanas", [-33.88995, -60.57357], "Arenaviruses | Hantaviruses");
        cc.addSite("University of California, Davis", [38.5449065, -121.7405167], "Coronaviruses");
        cc.addSite("Washington State University Global Health Kenya", [-1.286389, 36.817223], "Coronaviruses | Phenuviruses");
        cc.addSite("Kenya Medical Research Institute", [-1.28571, 36.820026], "Coronaviruses");
        cc.addSite("Aga Khan University (AKU)", [24.8607343, 67.0011364], "Nairoviruses");
        cc.addSite("Institut Pasteur", [48.856614, 2.3522219], "Arboviruses | Alphaviruses");
        cc.addSite("Tulane University", [29.9510659, -90.0715323], "Filoviruses");
        cc.addSite("Kathmandu Research Institute for Biological Sciences", [27.7733295, 85.6801414], "Flaviviruses");
        cc.addSite("Tribhuvan University", [27.6630019, 85.2774207], "Flaviviruses");
        cc.addSite("Escuela Nacional de Ciencias Biológicas – Instituto Politécnico Nacional (ENCB-IPN)", [19.4326296, -99.1331785], "Phenuviruses");
        cc.addSite("Gorgas Memorial Institute of Health Studies", [8.983333, -79.51667], "Alphaviruses");
        cc.addSite("Universidad San Francisco de Quito", [-0.2107252, -78.4410895], "Flaviviruses");
        cc.addSite("Hospital San Miguel", [0.11667, -75.86667], "Flaviviruses");
        cc.addSite("Universidad Autonoma de Yucatan", [20.9670759, -89.6237402], "Arboviruses");
        cc.addSite("Association Ekipa Fanihy", [-18.8791902, 47.5079055], "Coronaviruses | Filoviruses | Henipaviruses");
        cc.addSite("Kenema Government Hospital", [7.8632147, -11.1957172], "Filoviruses");
        cc.addSite("Uganda Virus Research Institute", [0.0511839, 32.463708], "Arboviruses");
        cc.addSite("Universidad Nacional Autonoma de Mexico", [19.43195, -99.13313], "Arboviruses");
        cc.addSite("Institute of Tropical Medicine Antwerp", [51.2194475, 4.4024643], "Arboviruses");
        cc.addSite("Universidad Peruana Cayetano Heredia", [-12.043429, -77.03562], "Arboviruses");
        cc.addSite("Iasi University of Life Sciences", [47.151726, 27.587914], "Arboviruses");
        cc.addSite("Centre Pasteur du Cameroun", [3.87037, 11.518411], "Flaviviruses | Togaviruses");
        this.researchCenters.push(cc);


        let a2cares: ResearchCenter = new ResearchCenter("A2CARES", "American and Asian Centers for Arboviral Research and Enhanced Surveillance", [37.8719, -122.2585], "#68217a", ["Arboviruses", "Chikungunya Virus", "Dengue Virus", "Flaviviruses", "SARS-CoV-2 (COVID-19)", "Zika Virus"], ["Ecuador", "Nicaragua", "Sri Lanka"], "a2cares", true);
        //a2cares.addSite("Managua, Nicaragua", [12.1150, -86.2362], ["Arboviruses", "Chikungunya", "Dengue Virus", "SARS-CoV-2 (COVID-19)", "Zika Virus"]);
        //a2cares.addSite("San Francisco de Quito, University (Ecuador)", [0.9682, -79.6517], ["Arboviruses", "Chikungunya", "Dengue Virus", "SARS-CoV-2 (COVID-19)", "Zika Virus"]);
        //a2cares.addSite("Centre for Dengue Research, University of Sri Jayewardenepura (Sri Lanka)", [6.8868, 79.9187], ["Dengue Virus", "Flaviviruses", "SARS-CoV-2 (COVID-19)"]);
        a2cares.addSite("California Academy of Sciences", [37.7749295, -122.4194155], "Togaviruses | Flaviviruses", true);
        a2cares.addSite("University of Sri Jayewardenepura, Centre for Dengue Research", [6.8649081, 79.8996789], "Flaviviruses", true);
        a2cares.addSite("Centro de Salud Edgar Lang", [12.1149926, -86.2361744], "Arboviruses", true);
        a2cares.addSite("University of North Carolina Chapel Hill, de Silva Lab", [35.9131996, -79.0558445], "Coronaviruses | Flaviviruses", true);
        a2cares.addSite("Emory University, Waggoner Lab", [33.7489954, -84.3879824], "Togaviruses |Bunyaviruses | Coronaviruses | Flaviviruses");
        a2cares.addSite("Universidad Central del Ecuador, INBIOMED", [-0.1806532, -78.4678382], "Flaviviruses", true);
        a2cares.addSite("Instituto Nacional de Investigación en Salud Pública-Quito", [-0.1806532, -78.4678382], "Togaviruses | Coronaviruses | Flaviviruses", true);
        a2cares.addSite("Nicaraguan National Virology Laboratory", [12.1149926, -86.2361744], "Togaviruses | Orthomyxoviruses | Togaviruses | Adenoviruses | Enteroviruses | Parvoviruses | Paramyxoviruses | Pneumoviruses | Picornaviruses | Coronaviruses | Flaviviruses", true);
        a2cares.addSite("Sustainable Sciences Institute", [12.1149926, -86.2361744], "Arboviruses | Coronaviruses", true);
        a2cares.addSite("Universidad San Francisco de Quito", [-0.2107252, -78.4410895], "Togaviruses | Coronaviruses | Flaviviruses", true);
        a2cares.addSite("University of California, Berkeley, Harris Lab", [37.8715226, -122.273042], "Flaviviruses");
        a2cares.addSite("University of Michigan", [42.2808256, -83.7430378], "Coronaviruses");
        a2cares.addSite("Base Hospital", [6.028849, 80.785576], "Arboviruses", true);
        a2cares.addSite("University of Sri Jayewardenepura, National Centre for Primary Care", [6.872916, 79.888634], "Arboviruses | Coronaviruses", true);
        a2cares.addSite("Columbia University", [40.7127753, -74.0059728], "Arboviruses | Pathogen X", true);
        this.researchCenters.push(a2cares);


        let create_neo: ResearchCenter = new ResearchCenter("CREATE-NEO", "Coordinating Research on Emerging Arboviral Threats Encompassing the Neotropics", [29.3113, -94.7781], "#dc21fe", ["Chikungunya Virus", "Dengue Virus", "Mayaro Virus", "Yellow Fever Virus", "Zika Virus"], ["Brazil", "Panama", "United States"], "create-neo", true);
        create_neo.addSite("Cary Institute of Ecosystem Studies", [41.7850937, -73.6940175], "");
        create_neo.addSite("Faculdade de Medicina de São José do Rio Preto (FAMERP)", [-20.8202, -49.3797], "Orthomyxoviruses | Togaviruses | Coronaviruses | Flaviviruses");
        create_neo.addSite("Fundacao de Medicina Tropical", [-3.1190275, -60.0217314], "Togaviruses | Flaviviruses");
        create_neo.addSite("Gorgas Memorial Institute of Health Studies", [8.983333, -79.51667], "");
        create_neo.addSite("Massachusetts Institute of Technology", [42.373611, -71.110558], "Flaviviruses");
        create_neo.addSite("New Mexico State University (NMSU)", [32.3199396, -106.7636538], "Togaviruses | Flaviviruses");
        create_neo.addSite("University of Texas Medical Branch (UTMB)", [29.3013479, -94.7976958], "Togaviruses | Flaviviruses", true);
        create_neo.addSite("Universidad Federal de Minas Gerais", [-19.9166813, -43.9344931], "Arboviruses | Arenaviruses | Bunyaviruses | Flaviviruses | Poxviruses | Reoviruses | Togaviruses | Pathogen X | Priority Pathogens");
        create_neo.addSite("Universidade Federal de Mato Grasso, Faculdade de Medicina Veterinaria", [-15.601411, -56.097892], "Arborviruses | Coronaviruses | Paramyxoviruses | Parvoviruses | Peribunyaviruses | Retroviruses | Togaviruses");
        this.researchCenters.push(create_neo);

        let creid_eca: ResearchCenter = new ResearchCenter("CREID-ECA", "Center for Research in Emerging Infectious Diseases-East and Central Africa", [47.6062095, -122.3320708], "#00bcf2", ["MERS-CoV", "Rift Valley Fever Virus"], ["DRC", "Kenya", "Tanzania", "Uganda"], "creid-eca");
        creid_eca.addSite("Charité-Universitätsmedizin Berlin", [52.501408, 13.4023285], "Coronaviruses");
        creid_eca.addSite("Emory University", [33.7489954, -84.3879824], "");
        creid_eca.addSite("Institut National de la Recherche BiomÃ©dicale (INRB)", [-4.440634, 15.913988], "Bunyaviruses");
        creid_eca.addSite("Institute of Tropical Medicine Antwerp", [51.2194475, 4.4024643], "");
        creid_eca.addSite("Kenya Medical Research Institute", [-1.28571, 36.820026], "Coronaviruses | Bunyaviruses");
        creid_eca.addSite("Sokoine University of Agriculture", [-6.8277556, 37.6591144], "Bunyaviruses");
        creid_eca.addSite("Washington University St. Louis", [38.6270025, -90.1994042], "Coronaviruses | Phenuiviruses");
        creid_eca.addSite("Uganda Virus Research Institute", [0.0511839, 32.463708], "Bunyaviruses");
        creid_eca.addSite("Washington State University", [47.6062095, -122.3320708], "Coronaviruses | Phenuiviruses");
        creid_eca.addSite("International Livestock Research Institute", [-1.24798, 36.72316], "");
        creid_eca.addSite("Makerere University", [0.3499986, 32.567164398], "Filoviruses");
        creid_eca.addSite("Uganda Ministry of Health", [0.333044, 32.577486], "Filoviruses");
        creid_eca.addSite("Uganda Ministry of Health, Central Public Health Laboratories", [0.3173429, 32.5769708], "Filoviruses");
        creid_eca.addSite("Washington State University Global Health Kenya", [-1.286389, 36.817223], "Coronaviruses | Phenuiviruses");
        this.researchCenters.push(creid_eca);

        let creid_esp: ResearchCenter = new ResearchCenter("CREID-ESP", "Center for Research in Emerging Infectious Disease-Epidemiology, Surveillance, Pathogenesis", [38.6488, -90.3108], "#00b294", ["Arboviruses", "Encephalitis", "MERS-CoV", "SARS-CoV-2 (COVID-19)"], ["China", "Ethiopia", "Hong Kong", "Nepal", "United States"], "creid-esp");
        creid_esp.addSite("Ethiopian Public Health Institute", [8.9806034, 38.7577605], "Togaviruses | Bunyaviruses | Filoviruses | Orthomyxoviruses | Arenaviruses | Coronaviruses | Paramyxoviruses |  Pathogen X | Flaviviruses");
        //creid_esp.addSite("China CDC, Institute for Viral Disease Control and Prevention", [39.9041999, 116.4073963], "Flaviviruses");
        creid_esp.addSite("La Jolla Institute for Immunology", [32.8328112, -117.2712717], "Coronaviruses | Flaviviruses");
        creid_esp.addSite("Nepal Ministry of Health and Population, National Public Health Laboratory", [27.7172453, 85.3239605], "Togaviruses | Orthomyxoviruses | Coronaviruses | Flaviviruses");
        creid_esp.addSite("Nepal Ministry of Health and Population, Sukraraj Tropical and Infectious Disease Hospital", [27.7172453, 85.3239605], "");
        creid_esp.addSite("The University of Hong Kong", [22.2679201, 114.1290719], "Coronaviruses");
        creid_esp.addSite("Tribhuvan University", [27.6630019, 85.2774207], "Bunyaviruses | Togaviruses | Orthomyxoviruses | Coronaviruses | Paramyxoviruses | Flaviviruses");
        creid_esp.addSite("Washington University St. Louis", [38.6270025, -90.1994042], "Togaviruses | Orthomyxoviruses | Coronaviruses");
        creid_esp.addSite("Hallym University", [37.86963, 127.73868], "Paramyxoviruses");
        this.researchCenters.push(creid_esp);

        let eeidi: ResearchCenter = new ResearchCenter("EpiCenter", "EpiCenter for Emerging Infectious Disease Intelligence", [41, -121.7617], "#e81123", ["Arboviruses", "Coronaviruses", "Filoviruses", "Paramyxoviruses", "Retroviruses"], ["Peru", "Uganda", "United States"], "eeidi");
        eeidi.addSite("Bwindi Community Hospital", [-1.0521495, 29.6201006], "Togaviruses | Filoviruses | Bunyaviruses | Coronaviruses | Flaviviruses");
        eeidi.addSite("Colorado State University, Kading Laboratory", [40.5852602, -105.084423], "Togaviruses | Bunyaviruses | Flaviviruses");
        eeidi.addSite("Mountain Gorilla Veterinary Project", [0.347596, 32.58252], "Togaviruses | Filoviruses | Bunyaviruses | Coronaviruses | Flaviviruses");
        eeidi.addSite("Pontificia Universidad Católica del Perú", [-12.043429, -77.03562], "Togaviruses | Bunyaviruses | Coronaviruses | Flaviviruses | Orthomyxoviruses");
        eeidi.addSite("Rugarama Hospital", [-1.2447, 29.9836], "Togaviruses | Filoviruses | Bunyaviruses | Coronaviruses | Flaviviruses");
        //eeidi.addSite("Tulane University Health Office for Latin America", [-12.043429, -77.03562], "");
        eeidi.addSite("University of California, Davis, SVM Laboratories", [38.5449065, -121.7405167], "Togaviruses | Filoviruses | Bunyaviruses | Coronaviruses | Flaviviruses | Paramyxoviruses | Orthomyxoviruses");
        eeidi.addSite("Uganda Virus Research Institute", [0.0511839, 32.463708], "Togaviruses | Filoviruses | Bunyaviruses | Coronaviruses | Flaviviruses");
        //eeidi.addSite("CDC Viral Special Pathogens Branch, Colleagues", [33.7489954, -84.3879824], "Filoviruses");
        this.researchCenters.push(eeidi);

        //let eid_search: ResearchCenter = new ResearchCenter("EID-SEARCH", "Emerging Infectious Diseases: South East Asia Research Collaboration Hub", [40.753645, -73.991934], "#ec008c", ["Coronaviruses", "Filoviruses", "Paramyxoviruses"], ["Malaysia", "Thailand", "Singapore"], "eid-search", true);
        //eid_search.addSite("Chulalongkorn University, Chulalongkorn University Hospital", [13.7563309, 100.5017651], "Bunyaviruses | Filoviruses | Orthomyxoviruses | Coronaviruses | Paramyxoviruses | Pathogen X | Flaviviruses");
        //eid_search.addSite("Conservation Medicine", [3.20933, 101.5613392], "Filoviruses | Coronaviruses | Paramyxoviruses | Pathogen X");
        //eid_search.addSite("EcoHealth Alliance", [40.7127753, -74.0059728], "");
        //eid_search.addSite("Uniformed Services University", [38.984652, -77.0947092], "Filoviruses | Coronaviruses | Paramyxoviruses | Paramyxoviruses");
        //eid_search.addSite("University of North Carolina at Chapel Hill", [35.9131996, -79.0558445], "Coronaviruses");
        //eid_search.addSite("Boston University, National Emerging Infectious Diseases Laboratories", [42.3600825, -71.0588801], "");
        //this.researchCenters.push(eid_search);

        let picreid: ResearchCenter = new ResearchCenter("PICREID", "Pasteur International Center for Research on Emerging Infectious Diseases", [48.8408, 2.3109], "#009e49", ["Crimean Congo Hemorrhagic Fever Virus", "Dengue Virus", "Rift Valley Fever Virus"], ["Cambodia", "Cameroon", "France", "Senegal"], "picreid");
        picreid.addSite("Centre for Research in Infectious Diseases (CRID)", [3.87037, 11.518411], "Togaviruses | Bunyaviruses | Coronaviruses | Flaviviruses");
        picreid.addSite("Centre Pasteur du Cameroun", [3.87037, 11.518411], "Bunyaviruses | Togaviruses | Filoviruses | Orthomyxoviruses | Arenaviruses | Coronaviruses | Paramyxoviruses | Pathogen X | Flaviviruses");
        picreid.addSite("Institut Pasteur", [48.856614, 2.3522219], "Togaviruses | Flaviviruses");
        picreid.addSite("Institut Pasteur de Dakar", [14.716677, -17.4676861], "Bunyaviruses | Togaviruses | Flaviviruses");
        picreid.addSite("Institut Pasteur du Cambodge", [11.5563738, 104.9282099], "Togaviruses | Orthomyxoviruses | Paramyxoviruses | Coronaviruses | Pathogen X | Flaviviruses");
        picreid.addSite("University of Leipzig", [51.3396955, 12.3730747], "Togaviruses | Filoviruses | Orthomyxoviruses | Coronaviruses | Bunyaviruses | Pathogen X | Flaviviruses");
        picreid.addSite("Sivas Cumhuriyet University", [39.7076, 37.0296], "Narioviruses");
        this.researchCenters.push(picreid);

        let uwarn: ResearchCenter = new ResearchCenter("UWARN", "United World Antiviral Research Network", [47.655548, -122.3032], "#00188f", ["Arboviruses", "SARS-CoV-2 (COVID-19)", "All Emerging IDs"], ["Brazil", "Pakistan", "Senegal", "South Africa", "Taiwan", "United States"], "uwarn");
        uwarn.addSite("Aga Khan University (AKU)", [24.8607343, 67.0011364], "Coronaviruses");
        uwarn.addSite("University of Washington, Center for Emerging and Re-Emerging Infectious Disease (CERID)", [47.6062095, -122.3320708], "Coronaviruses");
        uwarn.addSite("University of Washington, Center for One Health Research (COHR)", [47.6062095, -122.3320708], "Arboviruses | Coronaviruses");
        uwarn.addSite("Universidad Federal de Minas Gerais, Eduardo de Menezes Hospital, LACENS", [-19.9166813, -43.9344931], "Arboviruses | Coronaviruses");
        uwarn.addSite("FIOCRUZ, Instituto GonÃ§alo Moniz", [-12.99918565, -38.4847644], "Togaviruses | Coronaviruses | Flaviviruses");
        uwarn.addSite("FIOCRUZ, Oswaldo Cruz Foundation", [-22.8830377, -43.2457507], "Bunyaviruses | Togaviruses | Filoviruses | Orthomyxoviruses | Paramyxoviruses | Coronaviruses | Flaviviruses");
        uwarn.addSite("Fred Hutchinson Cancer Research Center", [47.6062095, -122.3320708], "Arboviruses | Coronaviruses");
        uwarn.addSite("University of Washington, Institute for Protein Design", [47.6062095, -122.3320708], "Orthomyxoviruses | Coronaviruses");
        uwarn.addSite("Institute for Research in Biomedicine", [46.1946216, 9.0244124], "Coronaviruses | Arboviruses");
        uwarn.addSite("Kwazulu-Natal Research Innovation and Sequencing Platform (KRISP)", [-29.8586804, 31.0218404], "Togaviruses | Coronaviruses | Flaviviruses");
        uwarn.addSite("L'Institut de Recherche en Santé, de Surveillance Épidémiologique et de Formation (IRESSEF)", [14.67281, -17.43568], "Togaviruses | Coronaviruses | Flaviviruses");
        uwarn.addSite("The Rockefeller University, Nussenzweig Laboratory", [40.7127753, -74.0059728], "Bunyaviruses | Togaviruses | Orthomyxoviruses | Coronaviruses | Flaviviruses");
        uwarn.addSite("Chang Gung University, Research Center for Emerging Viral Infections", [24.9910531, 121.3223172], "Coronaviruses");
        uwarn.addSite("The Rockefeller University", [40.7127753, -74.0059728], "Flaviviruses");
        uwarn.addSite("Washington State Department of Health", [47.0073187, -122.9093063], "Arboviruses");
        uwarn.addSite("Stellenbosch University", [-33.9328, 18.8644], "Coronaviruses | Arboviruses");
        this.researchCenters.push(uwarn);

        let wac_eid: ResearchCenter = new ResearchCenter("WAC-EID", "West African Center for Emerging Infectious Diseases", [29.26, -94.85], "black", ["Chikungunya Virus", "Dengue Virus", "Ebola Virus", "Lassa Virus", "MERS Co-V", "Nipah Virus", "Yellow Fever Virus", "Zika Virus"], ["Nigeria", "Senegal", "Sierra Leone"], "wac-eid", true);
        //wac_eid.addSite("Duke-NUS Medical School", [1.352083, 103.819836], "");
        wac_eid.addSite("Institut Pasteur de Dakar", [14.716677, -17.4676861], "Flaviviruses | Coronaviruses | Arenaviruses | Hantaviruses");
        wac_eid.addSite("Jos University Teaching Hospital", [9.8965273, 8.8583309], "Arboviruses | Coronaviruses | Poxviruses | Bunyaviruses");
        wac_eid.addSite("Kenema Government Hospital", [7.8632147, -11.1957172], "Arboviruses | Filoviruses | Arenaviruses");
        wac_eid.addSite("National Veterinary Research Institute", [11.368577, 5.809693], "Filoviruses | Arenaviruses | Coronaviruses");
        wac_eid.addSite("Njala University", [7.96472, -11.73833], "Arboviruses | Flaviviruses | Arenaviruses | Filoviruses");
        wac_eid.addSite("University of Jos", [9.8965273, 8.8583309], "Arboviruses | Filoviruses | Poxviruses | Coronaviruses");
        wac_eid.addSite("Instituto Nacional de Enfermedades Virales Humanas", [-33.88995, -60.57357], "Arenaviruses | Bunyaviruses | Flaviviruses");
        wac_eid.addSite("University of California, Davis", [38.5449065, -121.7405167], "");
        this.researchCenters.push(wac_eid);

        let warn_id: ResearchCenter = new ResearchCenter("WARN-ID", "West African Research Network for Infectious Diseases", [32.8958, -117.2425], "#ff8c00", ["Lassa Virus", "SARS-CoV-2 (COVID-19)"], ["Liberia", "Nigeria", "Senegal", "Sierra Leone", "United States"], "warn-id");
        warn_id.addSite("Kenema Government Hospital", [7.8632147, -11.1957172], "Filoviruses | Arenaviruses | Coronaviruses | Flaviviruses");
        warn_id.addSite("National Institute of Health Liberia", [6.300774, -10.79716], "Filoviruses | Arenaviruses | Coronaviruses");
        warn_id.addSite("Redeemer's University", [7.730184, 4.517348], "Togaviruses | Filoviruses | Orthomyxoviruses | Arenaviruses | Coronaviruses | Flaviviruses");
        warn_id.addSite("The Broad Institute", [42.373611, -71.110558], "Filoviruses | Arenaviruses | Coronaviruses | Flaviviruses");
        warn_id.addSite("The Scripps Research Institute", [32.8328112, -117.2712717], "");
        warn_id.addSite("Tulane University", [29.9510658, -90.0715323], "Filoviruses | Arenaviruses | Coronaviruses | Flaviviruses");
        warn_id.addSite("Universite Cheikh Anta Diop", [14.716677, -17.4676861], "Bunyaviruses | Coronaviruses");
        this.researchCenters.push(warn_id);

        /*
        let piColor: string = "#02182B";
        this.researchCenters.push(new ResearchCenter("", "", [29.7105, -95.3965], piColor, [], [], ""));
        this.researchCenters.push(new ResearchCenter("", "", [32.7850, -79.9478], piColor, [], [], ""));
        this.researchCenters.push(new ResearchCenter("", "", [29.7199, -96.8422], piColor, [], [], ""));
        this.researchCenters.push(new ResearchCenter("", "", [33.2098, -87.5692], piColor, [], [], ""));
        this.researchCenters.push(new ResearchCenter("", "", [41.2705, -72.9470], piColor, [], [], ""));
        this.researchCenters.push(new ResearchCenter("", "", [32.7719, -117.1882], piColor, [], [], ""));
        this.researchCenters.push(new ResearchCenter("", "", [30.7131, -95.8977], piColor, [], [], ""));
        this.researchCenters.push(new ResearchCenter("", "", [42.3591, -83.0665], piColor, [], [], ""));
        this.researchCenters.push(new ResearchCenter("", "", [41.3032, -72.0339], piColor, [], [], ""));
        this.researchCenters.push(new ResearchCenter("", "", [38.0307, -84.5040], piColor, [], [], ""));
        this.researchCenters.push(new ResearchCenter("", "", [33.6958, -117.5425], piColor, [], [], ""));

        let dsmbColor: string = "#788AA3";
        this.researchCenters.push(new ResearchCenter("", "", [37.5483, -77.4527], dsmbColor, [], [], ""));
        this.researchCenters.push(new ResearchCenter("", "", [47.655548, -122.303200], dsmbColor, [], [], ""));
        this.researchCenters.push(new ResearchCenter("", "", [41.8705, -72.7470], dsmbColor, [], [], ""));
        this.researchCenters.push(new ResearchCenter("", "", [43.6490, -72.3193], dsmbColor, [], [], ""));
        this.researchCenters.push(new ResearchCenter("", "", [39.0840, -77.1528], dsmbColor, [], [], ""));        
        this.researchCenters.push(new ResearchCenter("", "", [42.7120, -73.2037], dsmbColor, [], [], ""));
        this.researchCenters.push(new ResearchCenter("", "", [34.0682, -98.1906], dsmbColor, [], [], ""));

        let pharmaColor: string = "#BE6E46";
        this.researchCenters.push(new ResearchCenter("", "", [40.8215, -73.9876], pharmaColor, [], [], ""));
        this.researchCenters.push(new ResearchCenter("", "", [41.3083, -71.2279], pharmaColor, [], [], ""));
        this.researchCenters.push(new ResearchCenter("", "", [39.2904, -76.6122], pharmaColor, [], [], ""));
        this.researchCenters.push(new ResearchCenter("", "", [38.2527, -85.7585], pharmaColor, [], [], ""));
        this.researchCenters.push(new ResearchCenter("", "", [37.4419, -122.1430], pharmaColor, [], [], ""));
        this.researchCenters.push(new ResearchCenter("", "", [41.2128, -74.7060], pharmaColor, [], [], ""));

        let reviewerColor: string = "#475841";
        let eeidi: ResearchCenter = new ResearchCenter("", "", [41.3083, 70.9279], reviewerColor, [], [], "");
        eeidi.addSite("", [41.9083, -71.9279], []);
        eeidi.addSite("", [30.2849, -97.7341], []);
        eeidi.addSite("", [29.1199, -95.9422], []);
        eeidi.addSite("", [29.5827, -98.6189], []);
        eeidi.addSite("", [47.6062, -123.0321], []);
        eeidi.addSite("", [35.9132, -79.0558], []);
        eeidi.addSite("", [38.0307, -83.5040], []);
        eeidi.addSite("", [35.8992, -79.8636], []);
        this.researchCenters.push(eeidi);
        */
    }
}