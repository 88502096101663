
//Durstenfeld Approach: https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle#The_modern_algorithm
export function ShuffleArray(array: any[]) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}

export function SplitAndJoin(input: string, splitDelimiter: string, outputDelimiter: string, sort: boolean, nowrap: boolean = false): string {
    let list: string[] = input.split(splitDelimiter);
    if (sort) { list.sort(); }
    let output: string = "";
    list.forEach((item: string, index: number) => {
        if (index > 0) output += outputDelimiter;
        if (nowrap) output += "<span class=\"nowrap\">";
        output += item;
        if (nowrap) output += "</span>";
    });
    return output;
}

export function Split(input: string, delimiter: string): string[] {
    let parts: string[] = input.split(delimiter);
    parts = parts.map((p: string) => p.trim());
    return parts;
}

export function Join(list: string[], outputDelimiter: string, sort: boolean, nowrap: boolean = false): string {
    if (sort) { list.sort(); }
    let output: string = "";
    list.forEach((item: string, index: number) => {
        if (index > 0) output += outputDelimiter;
        if (nowrap) output += "<span class=\"nowrap\">";
        output += item;
        if (nowrap) output += "</span>";
    });
    return output;
}

export function Unique(values: string[], sort: boolean, lastValues: string[] = []): string[] {

    values = values.map((val: string) => {
        if (val.startsWith("Other")) return "Other";
        else return val;
    });

    let unique: string[] = values.filter((n, i) => values.indexOf(n) === i);
    if (sort) {
        lastValues = lastValues.map((v: string) => v.toLowerCase());

        unique = unique.sort((a: string, b: string) => {
            a = a.toLowerCase();
            b = b.toLowerCase();
            
            if (lastValues.length == 0) { return (a > b ? 1 : -1); }
            else {
                let isALastValue: boolean = false;
                let isBLastValue: boolean = false;

                lastValues.forEach((v: string) => {
                    if (a.startsWith(v)) { isALastValue = true; }
                    if (b.startsWith(v)) { isBLastValue = true; }
                });

                if (!isALastValue && !isBLastValue) { return (a > b ? 1 : -1); }
                else if (isALastValue && !isBLastValue) {
                    return 1;
                }
                else if (!isALastValue && isBLastValue) {
                    return -1;
                }
                else {
                    return (a > b ? 1 : -1);
                }
            }

            //if (a.startsWith(v) && b.startsWith(v)) { return (a > b ? 1 : -1); }
            //else if (a.startsWith(v)) { return 1; }
            //else if (b.startsWith(v)) { return -1; }
            //else return (a > b ? 1 : -1);


        });

        //unique.sort();
    }
    return unique;    
}

export function FormatStringByWordLength(value: string, wordsOnLine: number): string {
    let parts: string[] = value.split(" ");
    let output: string = "<span class='nowrap'>";
    for (let i = 0; i < parts.length; ++i) {
        output += parts[i] + " ";
        if (i == (wordsOnLine - 1)) {
            output += "</span>";
        }
    }

    if (parts.length < (wordsOnLine - 1)) { output += "</span>"; }
    return output.trim();
}

export function DoArraysOverlap(array1: any, array2: any): boolean {
    if (array1 == undefined || array2 == undefined) return false;
    return array1.some(r => array2.includes(r));
}

export function NoWordWrap(input: string): string {
    return "<span class='nowrap'>" + input + "</span>";
}

export function CheckArrayStartsWith(array: string[], startsWithValue: string, appendValue: string): string[] {

    let includesValue: number = array.findIndex((value) => value.startsWith(startsWithValue));

    if (includesValue == -1) { return array; }
    else { array.push(appendValue); return array; }
}