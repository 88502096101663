import { plainToClass } from 'class-transformer';
import { Study } from './entities';

export class StudiesPage {

    studies: Study[];
    dataTable: any;

    constructor(studiesData: any) {
        $(() => {

            this.studies = plainToClass(Study, <Study[]>studiesData);
            this.studies.map((study: Study) => {
                study.initialize();
                return study;
            })

            console.log(this.studies.filter(study => study.IsPilotProgram))

            this.initializeTable();
            this.setStudiesFoundLabel(this.studies.length);
        });
    }

    initializeTable(): void {

        this.dataTable = $('#public-studies-table').DataTable({
            //"dom": '<"top-controls"<"message-section">f>rtip',
            "dom": '<"top-controls"<"reset-section">f>rtip',
            autoWidth: true,
            info: false,
            paging: false,
            search: true,
            scrollX: false,
            //scrollY: '70vh',
            orderCellsTop: true,
            language: { search: "Filter" },
            columns: [
                { className: 'details-control', orderable: false, data: null, defaultContent: '', width: '24px' },
                { data: "StudyNamePublic", className: "text-left pl-0" },
                { data: "ResearchCenter", className: "text-center", width: '15%' },
                { data: "PathogenFamilyDisplay", className: "text-center", width: '18%' },
                { data: "CountriesDisplay", className: "text-center", width: '15%' },
                { data: "Region", className: "text-center", width: '15%' },
                { data: "CombinedHiddenFilter", visible: false },
                { data: "PathogenFamilyList", visible: false },
                { data: "CountriesList", visible: false },
                { data: "RegionList", visible: false },
                { data: "StudyTypeDisplay", visible: false },
                { data: "IsPilotProgram", visible: false}
            ],
            data: this.studies,
            order: [[1, 'asc']]
        });

        let resetSectionDiv: string =
            "<div style='display: inline-block; float: left; margin-top: 5px'>" +
                "<button type=\"button\" class=\"btn btn-sm btn-outline-secondary font-size12\" onclick=\"page.resetFilters()\">Reset Filters</button>" +
            "</div>"
            ;

        let pilotCheckDiv: string =
            "<div class='form-check form-check-inline pl-3 mt-1'>" +
                "<input class='form-check-input mb-0' type = 'checkbox' id ='pilotCheckbox'>" +
                "<label class='form-check-label text-nowrap' for = 'pilotCheckbox'> Show Only Pilot Program Studies </label>" +
            "</div>"

        let filterDiv: string = 
            `<div class='d-flex align-items-center'>
                ${resetSectionDiv}
                ${pilotCheckDiv}
            </div>`


        $(".reset-section").empty().html(filterDiv);
        /*$(".reset-section").css('display', 'inline-block');
        $(".reset-section").css('float', 'left');
        $(".reset-section").css('margin-top', '5px');*/

        $(".top-controls").addClass(["d-flex", "justify-content-between"]);

        $('#public-studies-table tbody').on('click', 'td.details-control', (event) => {
            var tr = $(event.currentTarget).closest('tr');
            var row = this.dataTable.row(tr);

            if (row.child.isShown()) {
                // This row is already open - close it
                row.child.hide();
                tr.removeClass('shown');
            }
            else {
                // Open this row
                row.child(this.createDetailRow(row.data())).show();
                tr.addClass('shown');
            }
        });

        $('#public-studies-table').on('search.dt', (e, settings) => {
            this.setStudiesFoundLabel(this.dataTable.rows({ search: 'applied' }).count());
        });

        this.initializeFilters();
    }

    initializeFilters(): void {

        for (let index = 2; index <= 5; ++index) { //skip details and title column

            var dataFilterIndex = index;
            if (index == 3 || index == 4 || index == 5) {
                dataFilterIndex = index + 4;
            }

            var select = $('<select id="filter-dropdown-' + index + '"></select>')
                .appendTo($("#public-studies-table thead tr:eq(1) th").eq(index).empty())

            let uniqueValues: string[] = [];
            this.dataTable.column(dataFilterIndex).data().unique().map((data: any) => {
                let parts: string[];
                if (index == 3 || index == 4 || index == 5) {
                    parts = data;
                }
                else {
                    parts = data.split("|");
                }
                //console.log(parts);
                parts.map((part: string) => {
                    part = part.trim();
                    if (part.length > 0 && !uniqueValues.includes(part)) {
                        uniqueValues.push(part);
                    }
                });
            });

            uniqueValues = uniqueValues.sort();

            select.append($("<option></option>").attr("value", 'All').text('All'));
            //this.dataTable.column(index).data().unique().sort().each(function (d, j) {
            uniqueValues.map((d, j) => {
                select.append($("<option></option>").attr("value", d).text(d));
            });

            select.on('change', (event) => {
                let selected: string = <string>$(event.currentTarget).val();
                if (selected == 'All') selected = '';
                this.dataTable.column(index)
                    .search(selected)
                    .draw();
            });
        }

        // Initialize Pilot Program Checkbox
        $("#pilotCheckbox").on('change', () => {
            this.searchPilotProgram();
        })
    }

    createDetailRow(data: any): string {
        // `data` is the original data object for the row
        return '<table cellpadding="4" cellspacing="0" border="0" style="padding-left:75px;">' +
            '<tr class="detail-row"> ' +
                '<td class="text-left"><i>Date&nbsp;Range</i></td>' + 
                '<td class="text-left">' + data.StartDate + ' to ' + data.EndDate + '</td>' +
            '</tr>' +
            '<tr class="detail-row">' +
                '<td class="text-left nowrap"><i>Study Type(s)</i></td>' +
                '<td class="text-left">' + data.StudyTypeDisplay + '</td>' +
            '</tr>' +
            '<tr class="detail-row">' +
                '<td class="text-left"><i>Protocol</i></td>' +
                '<td class="text-left">' + data.StudyProtocolPublic + '</td>' +
            '</tr>' +
            '</table>';
    }


    showAcronymsDialog(): void {
        $('#acronyms-modal').modal('show');
    }

    resetFilters(): void {
        for (let index = 2; index <= 5; ++index) { //skip details and title column
            $('#filter-dropdown-' + index).val('All');

            this.dataTable.column(index).search('').draw();
        }

        this.dataTable.column(11).search('').draw();
        $("#pilotCheckbox").prop('checked', false);

        this.dataTable.search('').draw();
    }

    setStudiesFoundLabel(count: number): void {

        switch (count) {
            case 0: $('#studies-found-count').text("No Studies Found"); break;
            case 1: $('#studies-found-count').text("1 Study Found"); break;
            default: $('#studies-found-count').text(count + " Studies Found");
        }
    }

    searchPilotProgram(): void {
        // Get Value from Checkbox
        let isChecked = $("#pilotCheckbox").is(":checked")
        console.log(isChecked);

        // Search For Values
        let searchTerm = ''
        if (isChecked) {
            searchTerm = 'true'
        }
        this.dataTable.column(11).search(searchTerm).draw();
    }
}