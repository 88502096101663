
// SCRIPTS

import { GeneralHomePage } from './home/scripts/home.page'; export { GeneralHomePage }; //to make discoverable from cshtml
import { NetworkMapPage } from './network-map/scripts/network-map.page'; export { NetworkMapPage };
import { StudiesPage } from './studies/scripts/studies.page'; export { StudiesPage };
import { PublicationsPage } from './publications/scripts/publications.page'; export { PublicationsPage };
import { CoordinatingCenterPage } from './coord-center/coord-center.page'; export { CoordinatingCenterPage };
import { PilotProgramPage } from './pilot-program/pilot-program.page'; export { PilotProgramPage };
import { ConferencesPage } from './conferences/conferences.page'; export { ConferencesPage };


// STYLES
import './home/styles/home.scss';
import './home/styles/about-area.scss';
import './about/about.scss';
import './coord-center/coord-center.scss';
import './research-centers/research-centers.scss';
import './pathogens/pathogens.scss';
import './network-map/network-map.scss';
import './pilot-program/pilot-program.scss';
import './studies/studies.scss';
import './publications/publications.scss';
import './pilot-program/call-for-applications/call-for-applications.scss';
import './conferences/conferences.scss';
import './collaboration/collaboration.scss';
