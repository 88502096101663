import { SplitAndJoin, Split, Join, DoArraysOverlap } from "../../../shared/scripts/array-functions";

export class Study {

    ResearchCenter: string;
    StudyName: string;
    StudyNamePublic: string;
    StudyProtocol: string;
    StudyProtocolPublic: string;
    PathogenFamilies: string;
    Countries: string;
    StartDate: string;
    EndDate: string;
    HasHumanSubjects: string;
    HasAnimals: string;
    AnimalsUsed: string;
    Region: string;
    StudyTypes: string;
    HiddenFilter: string;
    CombinedHiddenFilter: string;
    IsPilotProgram: boolean;

    PathogenFamilyList: string[] = [];
    PathogenFamilyDisplay: string = "";
    CountriesList: string[] = [];
    CountriesDisplay: string = "";
    RegionList: string[] = [];
    RegionDisplay: string = "";
    StudyTypeDisplay: string = "";

    constructor() { }

    initialize(): void {

        //if (this.HasHumanSubjects == "True" && this.HasAnimals == "True") { this.Type = "Animal & Human"; }
        //else if (this.HasHumanSubjects == "True") { this.Type = "Human"; }
        //else if (this.HasAnimals == "True") { this.Type = "Animal"; }
        //else this.Type = "";

        if (this.PathogenFamilies != undefined && this.PathogenFamilies.length > 0) {
            this.PathogenFamilyList = Split(this.PathogenFamilies, "|");
            this.PathogenFamilyDisplay = Join(this.PathogenFamilyList, ", ", true, true);
        }
        

        if (this.Countries != undefined && this.Countries.length > 0) {
            this.CountriesList = Split(this.Countries, "|");
            this.CountriesDisplay = Join(this.CountriesList, ", ", true, true);
        }

        if (this.Region != undefined && this.Region.length > 0) {
            this.RegionList = Split(this.Region, "|");
            this.RegionDisplay = Join(this.RegionList, ", ", true, true);
        }

        if (this.StudyTypes != undefined && this.StudyTypes.length > 0) {
            this.StudyTypeDisplay = Join(Split(this.StudyTypes, "|"), ", ", true, true);
        }

        this.CombinedHiddenFilter = this.HiddenFilter + " " + this.Region + " " + this.StudyProtocolPublic;
    }
}