

export class CoordinatingCenterPage {

    constructor() {
        //$(() => {
        //});
    }

    showBioDialog(name: string, bioP1: string, bioP2: string, bioP3: string = "", link: string = ""): void {

        var modal = $('#bio-modal');
        modal.find('#bio-name').html(name);
        modal.find('#bio-p1').html(bioP1);
        modal.find('#bio-p2').html(bioP2);
        modal.find('#bio-p3').html(bioP3);

        if (link.length > 0) {
            modal.find('#bio-learn-more').html('<a href="' + link + '" target="_blank">More Information</a>');
        }
        else {
            modal.find('#bio-learn-more').html('');
        }

        modal.modal('show');
    }
}