import L, { Marker, Point } from "leaflet";
import { basemapLayer, featureLayer } from 'esri-leaflet';
import { ResearchCenter, Site } from "./entities";


export class NetworkMap {

    map: L.Map;
    basemapDetailedLayer: L.Layer;
    basemapGrayscaleLayer: L.Layer;
    markersLayerRC: L.LayerGroup;
    markersLayerSite: L.LayerGroup;
    markerRenderer: L.Renderer;
    clusterLayer: any;

    constructor(researchCenters: ResearchCenter[], pathogenFamiliesSelected: any) {

        $('#network-map').hide();
        $('#loading-indicator').show();

        this.initializeMap();
        this.setMarkers(researchCenters, pathogenFamiliesSelected);
    }

    initializeMap(): void {
        $("#network-map").empty();

        this.map = L.map('network-map', {
            zoomSnap: 0.1,
            worldCopyJump: true
        });

        this.basemapDetailedLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {});
        this.basemapGrayscaleLayer = basemapLayer('Gray').addTo(this.map);

        this.markersLayerSite = L.layerGroup().addTo(this.map);
        this.markersLayerRC = L.layerGroup().addTo(this.map);


        this.setupMarkerCluster();

        this.clusterLayer = L.markerClusterGroup(this.createClusterLayer()); 

        this.clusterLayer.on('clusterclick', function (a) {
            a.layer.spiderfy();
        });


        this.map.setView([28.5, 0], 2.05);

        this.markerRenderer = L.canvas({ padding: 0.5 });

        L.control.layers({
            "Detailed": this.basemapDetailedLayer,
            "Grayscale": this.basemapGrayscaleLayer
        }, {}).addTo(this.map);

        $('#loading-indicator').hide();
        $('#network-map').show();
        this.map.invalidateSize();

        this.map.on("baselayerchange", (e: any) => this.setAttribution(e.name));
        this.setAttribution("Grayscale");
    }

    createClusterLayer(): any {
        return {
            maxClusterRadius: 10,
            //disableClusteringAtZoom: this.map.options.maxZoom + 1
            zoomToBoundsOnClick: false,
            iconCreateFunction: function (cluster) {
                return new L.DivIcon({ html: cluster.getChildCount() + '', className: 'site-marker-cluster', iconSize: L.point(18, 18) });
            },
            spiderfyShapePositions: function (count: number, centerPt: { y: number; x: number; }) {
                var distanceFromCenter = 25,
                    markerDistance = 25,
                    lineLength = markerDistance * (count - 1),
                    lineStart = centerPt.y - lineLength / 2,
                    res = [],
                    i;

                res.length = count;

                for (i = count - 1; i >= 0; i--) {
                    res[i] = new Point(centerPt.x - distanceFromCenter, lineStart + markerDistance * i);
                }

                return res;
            }
        } as any; //casted to 'any' as shapePositions not part of type
    }

    setAttribution(basemap: string): void {

        if (basemap == "Grayscale") {
            $('.leaflet-control-attribution').html('Powered by <a href="https://www.esri.com">Esri</a>');
        }
        else if (basemap == "Detailed") {
            $('.leaflet-control-attribution').html('Powered by <a href="https://www.openstreetmap.org/">OpenStreetMap</a>');
        }
    }

    setMarkers(researchCenters: ResearchCenter[], pathogenFamiliesSelected: any) {

        if (this.map.hasLayer(this.markersLayerRC)) {
            this.markersLayerRC.clearLayers();
        }
        if (this.map.hasLayer(this.markersLayerSite)) {
            this.markersLayerSite.clearLayers();
        }
        if (this.map.hasLayer(this.clusterLayer)) {
            this.clusterLayer.clearLayers();
        }

        researchCenters.forEach((rcenter: ResearchCenter) => {

            if (rcenter.matchesFilter(pathogenFamiliesSelected)) {
                if (rcenter.isPartOfMapCluster) { //UTMB
                    this.clusterLayer.addLayer(L.circleMarker([rcenter.coords[0], rcenter.coords[1]], {
                        renderer: this.markerRenderer,
                        color: rcenter.color,
                        radius: 6,
                        fillOpacity: 1.0
                    }).addTo(this.markersLayerRC).bindPopup(this.createResearhCenterPopupContent(rcenter)));
                }
                else {
                    L.circleMarker([rcenter.coords[0], rcenter.coords[1]], {
                        renderer: this.markerRenderer,
                        color: rcenter.color,
                        radius: 6,
                        fillOpacity: 1.0
                    }).addTo(this.markersLayerRC).bindPopup(this.createResearhCenterPopupContent(rcenter));
                }

                rcenter.sites.forEach((site: Site) => {

                    if (site.matchesFilter(pathogenFamiliesSelected)) {
                        if (site.isPartOfMapCluster) {
                            this.clusterLayer.addLayer((<any>L).shapeMarker([site.coords[0], site.coords[1]], {
                                shape: 'triangle',
                                color: rcenter.color,
                                radius: 5,
                                weight: 1,
                                fillOpacity: 0.5
                            }).addTo(this.markersLayerSite).bindPopup(this.createSitePopupContent(site, rcenter.name, rcenter.abbreviation)));
                        }
                        else {
                            (<any>L).shapeMarker([site.coords[0], site.coords[1]], {
                                shape: 'triangle',
                                color: rcenter.color,
                                radius: 5,
                                weight: 1,
                                fillOpacity: 0.5
                            }).addTo(this.markersLayerSite).bindPopup(this.createSitePopupContent(site, rcenter.name, rcenter.abbreviation));
                        }
                    }
                });
            }
        });
        this.map.addLayer(this.clusterLayer);
    }

    createResearhCenterPopupContent(rcenter: ResearchCenter): string {

        let popupContent: string = "<div class='map-popup'>";
        popupContent += "<div class='location font-italic mb-1'>Research Center</div>";
        popupContent += "<div class='title'> " + rcenter.name + " </div>";
        popupContent += "<div class='title'>(" + rcenter.abbreviation + ")</div>";
        popupContent += "<div class='location mt-2'> ";
        popupContent += rcenter.sites.length + " Research Sites";
        popupContent += "</div>";
        //popupContent += "<div class='location'> ";
        //for (let i = 0; i < rcenter.countries.length; ++i) {
        //    popupContent += rcenter.countries[i];
        //    if (i < rcenter.countries.length - 1) {
        //        popupContent += ", ";
        //    }
        //}
        //popupContent += "</div>";

        //popupContent += "<table><tr><th>Pathogens</th></tr>";
        //rcenter.pathogens.forEach(pathogen => {
        //    popupContent += "<tr><td>" + pathogen + "</td></tr>";
        //});
        //popupContent += "</table>";

        if (rcenter.detailsLink != "creid-cc") {
            popupContent += "<div class='learn-more'><a href=" + "/research-centers/" + rcenter.detailsLink + " target='_blank'> Learn More </a></div>";
        }
        else {
            popupContent += "<div class='learn-more'><a href='https://creid-network.org/coordinating-center' target='_blank'> Learn More: CC</a></div>";
            popupContent += "<div class='learn-more'><a href='https://creid-network.org/pilot-program' target='_blank'> Learn More: Pilot Program</a></div>";
        }

        //popupContent += "</div>";
        return popupContent;
    }

    createSitePopupContent(site: Site, rcName: string, rcAbbre: string): string {

        let popupContent: string = "<div class='map-popup'>";
        popupContent += "<div class='location font-italic'>Research Site</div>";
        popupContent += "<div class='title'>" + site.name + " </div>";
        popupContent += "<div class='location'>" + rcName + " (" + rcAbbre + ")" + "</div>";

        if (site.pathogenFamilyList.length > 0) {
            popupContent += "<table><tr><th>Pathogen Families</th></tr>";
            site.pathogenFamilyList.forEach(pathogenFamily => {
                popupContent += "<tr><td>" + pathogenFamily + "</td></tr>";
            });
            popupContent += "</table>";
        }

        popupContent += "</div>";
        return popupContent;
    }


    //hopefully will be merged into official marker cluster release (if that ever happens again)
    setupMarkerCluster(): void {
        L.MarkerCluster.include({
            spiderfy: function () {
                if (this._group._spiderfied === this || this._group._inZoomAnimation) {
                    return;
                }

                var childMarkers = this.getAllChildMarkers(null, true),
                    group = this._group,
                    map = group._map,
                    center = map.latLngToLayerPoint(this._latlng),
                    positions;

                this._group._unspiderfy();
                this._group._spiderfied = this;

                //TODO Maybe: childMarkers order by distance to center

                if (this._group.options.spiderfyShapePositions) {
                    positions = this._group.options.spiderfyShapePositions(childMarkers.length, center);
                } else if (childMarkers.length >= this._circleSpiralSwitchover) {
                    positions = this._generatePointsSpiral(childMarkers.length, center);
                } else {
                    center.y += 10; // Otherwise circles look wrong => hack for standard blue icon, renders differently for other icons.
                    positions = this._generatePointsCircle(childMarkers.length, center);
                }

                this._animationSpiderfy(childMarkers, positions);
            }
        });
    }
}
