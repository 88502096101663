import { Split, DoArraysOverlap } from "../../../shared/scripts/array-functions";

export class ResearchCenter {
    sites: Site[] = [];
    pathogenFamilyList: string[] = [];

    constructor(readonly abbreviation: string, readonly name: string, readonly coords: number[], readonly color: string, readonly pathogens: string[], readonly countries: string[], readonly detailsLink: string, readonly isPartOfMapCluster: boolean = true  ) {}

    //getName(splitAbbrev: boolean): string {
    //    if (splitAbbrev) {
    //        let parts: string[] = this.name.split("(");
    //        return parts[0] + "<br />(" + parts[1];
    //    }
    //    else return this.name;
    //}

    addSite(name: string, coords: number[], pathogens: string, isPartOfMapCluster: boolean = true) {
        let site: Site = new Site(name, coords, pathogens, isPartOfMapCluster);
        this.sites.push(site);

        site.pathogenFamilyList.forEach((pathFam: string) => {
            if (!this.pathogenFamilyList.includes(pathFam)) this.pathogenFamilyList.push(pathFam);
        });

    }

    matchesFilter(pathogens: any): boolean {
        if (!DoArraysOverlap(pathogens, this.pathogenFamilyList)) return false;
        return true;
    }
}

export class Site {

    pathogenFamilyList: string[];

    constructor(readonly name: string, readonly coords: number[], readonly pathogens: string, readonly isPartOfMapCluster: boolean = true) {

        if (this.pathogens == undefined || this.pathogens.length == 0) {
            this.pathogenFamilyList = ["Not Available"];
        }
        else {
            this.pathogenFamilyList = Split(pathogens, "|");
            this.pathogenFamilyList.sort();
        }
    }

    matchesFilter(pathogens: any): boolean {
        if (!DoArraysOverlap(pathogens, this.pathogenFamilyList)) return false;
        return true;
    }
}