import { plainToClass } from 'class-transformer';
import { DoArraysOverlap } from '../../shared/scripts/array-functions';
import { ConferenceEntity } from './conferences.objects';

export class ConferencesPage {
    conferences: ConferenceEntity[];
    dataTable: any;

    constructor(conferencesJson: any) {

        //document.ready
        $(() => {
            this.conferences = plainToClass(ConferenceEntity, <ConferenceEntity[]>conferencesJson);
            this.conferences.map((conference: ConferenceEntity) => {
                conference.initialize();
                return conference;
            });

            //console.log(this.conferences);

            this.initializeTable();
            this.setConferencesFoundLabel(this.conferences.length);
        });

    }

    initializeTable(): void {

        this.dataTable = $('#creid-conferences-table').DataTable({
            "dom": '<"top-controls"fB>rtip',
            autoWidth: false,
            info: false,
            paging: false,
            search: true,
            scrollX: false,
            //scrollY: '70vh',
            orderCellsTop: true,
            language:
            {
                search: "Search:",
                buttons: {
                    copyTitle: 'Conference Data Copied',
                    copyKeys: 'Use your keyboard or menu to select the copy command'
                }
            },
            columns: [
                { className: 'details-control', orderable: false, data: null, defaultContent: '', width: '24px' }, //0
                { data: { _: "PresentationTitle", display: "DisplayPresentation" }, className: "text-left pl-0" }, //1
                { data: { _: "FullDate", display: "DisplayDate" }, className: "text-left font-size12" }, //2
                { data: { _: "ConferenceName", display: "DisplayConference" }, className: "text-left font-size12" }, //3
                { data: { _: "CreidResearchCenter", display: "DisplayResearchCenter"}, className: "text-left font-size12", width: "125px" }, //4
            ],
            buttons: [
                {
                    extend: 'copy',
                    text: '<i class="fas fa-copy"></i>',
                    titleAttr: 'Copy',
                    charset: 'utf-8',
                    exportOptions: {
                        columns: [0, 1, 2, 3, 4]
                    },
                    title: ''
                }
                , {
                    extend: 'csv',
                    text: '<i class="fas fa-file-download"></i>',
                    titleAttr: 'CSV',
                    charset: 'utf-8',
                    exportOptions: {
                        columns: [0, 1, 2, 3, 4]
                    }
                },
            ],
            data: this.conferences,
            order: [2, 'desc'],
            initComplete: (settings, json) => {
                $("#creid-conferences-table").wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
            },
        });

        $(".top-controls").addClass("row col-12 mx-0 px-0 align-items-center");

        //$("#creid-conferences-table_filter").after($("#filter-dropdown-row"));
        $("#creid-conferences-table_filter").addClass("col-md-4 pl-0 pr-4");
        $(".dt-buttons").addClass("mt-3 ml-3");
        $(".dt-buttons").before($("#found-count-section"));
        $("#found-count-section").addClass("ml-auto mt-3");


        //on td.details-control removed
        $('#creid-conferences-table tbody').on('click', 'tr', (conference) => {
            //var tr = $(event.currentTarget).closest('tr');
            var tr = $(conference.currentTarget);
            var row = this.dataTable.row(tr);

            if (row.child.isShown()) {
                // This row is already open - close it
                row.child.hide();
                tr.removeClass('shown');
            }
            else {
                // Open this row
                row.child(this.createDetailRow(row.data())).show();
                tr.addClass('shown');
            }
        });

        $('#creid-conferences-table').on('search.dt', (e, settings) => {
            this.setConferencesFoundLabel(this.dataTable.rows({ search: 'applied' }).count());
        });


        //setTimeout(() => { this.initializeFilters(); }, 500);
        this.initializeFilters();
    }

    initializeFilters(): void {

        for (let index = 3; index <= 4; ++index) { 

            let filterIndex: number = index; 

            let uniqueValues: string[] = [];
            let delimiter: string = ",";


            this.dataTable.column(filterIndex).data().unique().map((data: string) => {

                if (typeof (data) === 'string') {
                    let parts: string[] = data.split(delimiter);

                    parts.map((part: string) => {
                        part = part.trim();
                        if (part.length > 0 && !uniqueValues.includes(part)) {
                            uniqueValues.push(part);
                        }
                    });
                }

            });

            uniqueValues = uniqueValues.sort((a: string, b: string) => {
                return a.toLowerCase().localeCompare(b.toLowerCase());
            });

            //console.log(`uniqueValues: ${uniqueValues}`);

            //select.append($("<option></option>").attr("value", 'All').text('All'));
            //uniqueValues.map((d, j) => {
            //    select.append($("<option></option>").attr("value", d).text(d));
            //});

            let selectId: string = '#filter-dropdown-' + index;

            $(selectId).selectpicker({
                dropupAuto: false,
                selectedTextFormat: "count",

                countSelectedText: (numSelected, numTotal) => {
                    if (numSelected == numTotal) {
                        return "All";
                    }
                    else {
                        return numSelected + " Selected";
                    }
                }
            });

            uniqueValues.forEach((element: any) => {
                $(selectId).append(new Option(element, element, false, true));
            });
            $(selectId).selectpicker("refresh");


            $(selectId).on('changed.bs.select', (e, clickedIndex, isSelected, previousValue) => {
                this.triggerFilter();
            });

        }
    }

    createDetailRow(data: ConferenceEntity): string {
        // `data` is the original data object for the row
        let detailRow: string = '<table cellpadding="4" cellspacing="0" border="0" style="padding-left:75px;">';

        detailRow += '<tr class=""> ' +
            '<td class="text-left font-size12" style="width:115px"><i>Presentation Title</i></td>' +
            '<td class="text-left">' + data.DisplayPresentation + '</td>' +
            '</tr>';

        detailRow += '<tr class=""> ' +
            '<td class="text-left font-size12"><i>Conference</i></td>' +
            '<td class="text-left">' + data.DisplayConference + '</td>' +
            '</tr>';

       
        detailRow += '<tr class=""> ' +
            '<td class="text-left font-size12"><i>Year</i></td>' +
            '<td class="text-left">' + data.Year + '</td>' +
            '</tr>';

        detailRow += '<tr class=""> ' +
            '<td class="text-left font-size12"><i>Research Center(s)</i></td>' +
            '<td class="text-left">' + data.CreidResearchCenter.replace("|", ", ") + '</td>' +
            '</tr>';

        detailRow += '<tr class=""> ' +
            '<td class="text-left font-size12"><i>Presentation Format</i></td>' +
            '<td class="text-left">' + data.PresentationFormat + '</td>' +
            '</tr>';

        detailRow += '<tr class="">' +
            '<td class="text-left font-size12"><i>Presenter(s)</i></td>' +
            '<td class="text-left">' + data.Presenters.join(", ") + '</td>' +
            '</tr>';

        if (data.ExternalLink != "" && data.ExternalLink != undefined) {
            detailRow += '<tr class="">' +
                '<td></td>' +
                '<td class="text-right"><a href="' + data.ExternalLink + '" target="_blank" class="butn small"><span>Additional Information <i class="fa fa-external-link-alt link-icon" aria-hidden="true"</i></span></a></td>' +
                '</tr>';
        }

        detailRow += '</table>';

        return detailRow;
    }

    triggerFilter(): void {
        let currentConferences = this.conferences;

        // Conference Name
        let selectedConferences = <string[]>$("#filter-dropdown-3").val();
        currentConferences = currentConferences.filter(item => DoArraysOverlap([item.ConferenceName], selectedConferences));

        // Research Centers
        let selectedCenters = <string[]>$("#filter-dropdown-4").val();
        currentConferences = currentConferences.filter(item => DoArraysOverlap([item.CreidResearchCenter], selectedCenters));

        this.dataTable.clear();
        this.dataTable.rows.add(currentConferences);
        this.dataTable.draw();
    }

    resetFilters(): void {
        let filterIndex: number;
        for (let index = 4; index >= 3; --index) { //skip details and title column

            $('#filter-dropdown-' + index).selectpicker('selectAll');
            $('#filter-dropdown-' + index).selectpicker("refresh");
            this.dataTable.column(filterIndex).search('').draw();
        }
        this.dataTable.search('').draw();
    }

    setConferencesFoundLabel(count: number): void {

        switch (count) {
            case 0: $('#conference-found-count').text("No Presentations Found"); break;
            case 1: $('#conference-found-count').text("1 Presentation Found"); break;
            default: $('#conference-found-count').text(count + " Presentations Found");
        }
    }
}