//import { UserExperience } from "../../../auth/pages/profile/scripts/expertise.objects";
import { FormatStringByWordLength, DoArraysOverlap, SplitAndJoin, Split, Join } from "../../shared/scripts/array-functions";
import { FormatDate2 } from "../../shared/scripts/date-functions";
import { contains } from "jquery";

export class ConferenceEntity {

    ConferenceName: string;
    PresentationTitle: string;
    //Date: string;
    CreidResearchCenter: string;
    PresentationFormat: string;
    ExternalLink: string;
    ConferenceLink: string;
    PresentationLink: string;
    Presenters: string[];

    Month: number;
    Year: number;

    DisplayPresentation: string;
    DisplayConference: string;
    DisplayResearchCenter: string;
    DisplayDate: string;
    FullDate: string;

    constructor() { }

    initialize(): void {

        if (this.PresentationLink != "" && this.PresentationLink != undefined) {
            this.DisplayPresentation = `<a href="${this.PresentationLink}" target="_blank">${this.PresentationTitle}</a>`
        }
        else {
            this.DisplayPresentation = this.PresentationTitle;
        }

        if (this.ConferenceLink != "" && this.ConferenceLink != undefined) {
            this.DisplayConference = `<a href="${this.ConferenceLink}" target="_blank">${this.ConferenceName}<i class="fa fa-external-link-alt link-icon" aria-hidden="true"</i></a>`
        }
        else {
            this.DisplayConference = this.ConferenceName;
        }

        this.DisplayDate = this.Year.toString();
        this.FullDate = this.Year + "-" + this.Month;
        this.DisplayResearchCenter = this.CreidResearchCenter.replace("|", "<br/>");
        this.CreidResearchCenter = this.CreidResearchCenter.replace("|", ", ");
    }

    
    matchesFilter(affiliation: any, organization: any): boolean {

        //console.log(affiliation, this.Affiliation);

        /*if (!DoArraysOverlap(affiliation, this.AffiliationList)) { return false; }
        if (!DoArraysOverlap(organization, this.Organization)) { return false; }*/

        return true;
    }
}